/*----------- Header -------------*/

/*---Heade-color---*/
.color-header{
    .country-selector .nav-link {
        color: #fff !important;
    }
    .country-selector {
        padding: 0.3rem;
        border-right: 1px solid #7d67d4 !important;
      }
    @media (min-width:992px) {
        .hor-header .demo-icon {
            border-right:1px solid #7d67d4 !important;
        }
        .nav-link.theme-layout {
            border-left:1px solid #7d67d4 !important;
        }
    }
    .hor-header {
        border-bottom: 1px solid rgba(255,255,255,0.1);
    }
    .profile-dropdown {
        border-right: 1px solid #7d67d4 !important;
    }
    .collapse:not(.show) {
        display: none;
        background: transparent;
    }
    .header {
        background: #664dc9;
    }
    .app-sidebar__toggle {
        border-right: 1px solid #7d67d4;
    }
    .app-header{
        border-bottom: 1px solid #7d67d4 !important;
    }
    .header-icon{
        fill: #fff !important;
    }
    .header .form-inline .form-control {
        background: #664dc9;
        color: #fff;
    }
    .form-control::placeholder{
        color: #ffffff !important;
    }
    &.dark-mode {
        .form-control::placeholder {
             color: #ffffff !important;
             opacity: 0.5 !important;
        }
    }
    .nav-link.icon .header-icon{
        background: transparent;
    }
    .header .main-header-end .nav-link.icon{
        border-right: 1px solid #7d67d4;
    }
    #fullscreen-button {
        border-left: 1px solid #7d67d4;
    }
    .hor-header { 
        .form-control {
            border: 1px solid #7d67d4 !important;
        }
        .search-element .btn {
            background-color: transparent !important;
        }
        @media (max-width: 992px) {
            .form-control:focus {
                border-color: #32394e !important;
            }
            .animated-arrow span{
                background: #ffffff !important;
            }
            .animated-arrow span:before, .animated-arrow span:after {
                background: #ffffff !important;
            }
        }
        .desktop-lgo,.mobile-logo,.darkmobile-logo {
            display: none !important;
        }
        .dark-logo {
            display: block !important;
            margin: 0 auto;
        }
        .form-inline .btn .search-icon {
            fill: #ccd4e0 !important;
        }
    }
            @media (min-width: 767px) {
        .hor-header {
            .profile-dropdown img {
                box-shadow: 0 0 0 4px #755dd3;
                border: 2px solid #755dd3;
            }
        }
        .profile-dropdown img {
            box-shadow: 0px 2px 3px rgba(4, 4, 7 , 0.1);
            border: 2px solid #755dd3;
        }
    }
    @media (max-width: 767px) {
        .country-selector .nav-link {
            color: #6c756e !important;
        }
        .country-selector {
            padding: 0.3rem;
            border-right: 1px solid #e6ebf1 !important;
          }
        &.dark-mode {
            .country-selector .nav-link {
                color: #fff !important;
            }
            .country-selector {
                padding: 0.3rem;
                border-right: 1px solid #32394e !important;
            }
        }  
        .demo-icon svg {
            fill: #fff !important;
        }
        .header-icon.settings-icon {
            fill:#fff !important;
        }
        &.dark-mode .header-icon.settings-icon {
            fill:#fff !important;
        }
        .profile-dropdown {
            border-right: 1px solid #e6ebf1 !important;
        }
        &.dark-mode .profile-dropdown {
            border-right: 1px solid #32394e !important;
        }
        .hor-header {
            .navbar-toggler {
                top: -10px;
            }
            .animated-arrow span {
                background: #ffffff;
            }
            .animated-arrow span:before,.animated-arrow span:after {
                background: #ffffff;
            }
        }
        .profile-dropdown .header-icon{
            fill:#555b6d !important;
        }
        .header .main-header-end .nav-link.icon {
            border-right: 1px solid #e6ebf1;
        }
        &.dark-mode .header .main-header-end .nav-link.icon {
            border-right: 1px solid #32394e;
        }
        &.dark-mode .profile-dropdown .header-icon{
            fill: #ccd4e0 !important;
        }
        .theme-layout{
            border-left: 0px !important; 
        }
        .desktop-lgo,.darkmobile-logo,.mobile-logo{
            display: none !important;
        }
        .header-brand-img.dark-logo {
            display: block !important;
            margin: 0 auto;
            text-align: center;
            left: 0;
            right: 0;
            position: absolute;
        }
        .header-icon{
            fill:#555b6d !important;
        }
        .app-sidebar__toggle .header-icon{
            fill: #fff !important;
        }
        .nav-link.icon .header-icon {
            background: transparent;
        }
        .nav-link.icon .header-icon {
            margin-top: 3px; 
        }
        .hader-message{
            margin-left:0px;
        }
        &.dark-mode .header-icon{
            fill: #ccd4e0 !important;
        }
    }
    .navbar-toggler-icon {
        color: #fff;
    }
    .navbar-toggler:focus {
        box-shadow: none;
    }
}

.dark-header{
    .country-selector .nav-link {
        color: #fff !important;
    }
    .country-selector {
        padding: 0.3rem;
        border-right: 1px solid #32394e !important;
      }
    @media (min-width:992px) {
        
        .hor-header .demo-icon {
            border-right:1px solid #32394e !important;
        }
        .nav-link.theme-layout {
            border-left:1px solid #32394e !important;
        }
    }
    .hor-header {
        border-bottom: 1px solid rgba(255,255,255,0.1);
    }
    .hor-header {
        .desktop-lgo,.darkmobile-logo,.mobile-logo {
            display: none !important;
        }
        .dark-logo {
            display: block !important;
        }
        @media (max-width: 992px) {
            .animated-arrow span{
                background: #ffffff !important;
            }
            .animated-arrow span:before, .animated-arrow span:after {
                background: #ffffff !important;
            }
            .theme-layout {
                border-left: 0px !important;
            }
        }
    }
    .profile-dropdown {
        border-right: 1px solid #32394e !important;
    }
    @media (min-width: 768px) {
        .hor-header .header-search {
            border: 1px solid #32394e !important;
        }
        .hor-header .search-element .btn {
            background-color: transparent !important;
            .search-icon {
                fill: #ccd4e0 !important;
            }
        }
        .profile-dropdown img {
            box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
            border: 2px solid #32394e;
          }
    }
    .collapse:not(.show) {
        display: none;
        background: transparent ;
    }
    .header {
        background: #2a2e3f;
    }
    .app-sidebar__toggle {
        border-right: 1px solid #32394e;
    }
    .app-header{
        border-bottom: 1px solid #32394e !important;
    }
    .header-icon{
        fill: #fff !important;
    }
    .header .form-inline .form-control {
        background: #2a2e3f;
        color: #fff;
    }
    .form-control::placeholder{
        color: #ffffff !important;
    }
    &.dark-mode {
        .form-control::placeholder {
             color: #ffffff !important;
             opacity: 0.5 !important;
        }
    }
    .nav-link.icon .header-icon{
        background: transparent;
    }
    .header .main-header-end .nav-link.icon{
        border-right: 1px solid #32394e;
    }
    #fullscreen-button {
        border-left: 1px solid #32394e;
    }
    @media (max-width: 767px) {
        .country-selector .nav-link {
            color: #6c756e !important;
        }
        .country-selector {
            padding: 0.3rem;
            border-right: 1px solid #e6ebf1 !important;
          }
          &.dark-mode {
            .country-selector .nav-link {
                color: #fff !important;
            }
            .country-selector {
                padding: 0.3rem;
                border-right: 1px solid #32394e !important;
              }
          }
        .demo-icon svg {
            fill: #fff !important;
        }
        .profile-dropdown {
            border-right: 1px solid #e6ebf1 !important;
        }
        &.dark-mode .profile-dropdown {
            border-right: 1px solid #32394e !important;
        }
        .header-icon.settings-icon {
            fill:#fff !important;
        }
        .profile-dropdown img {
            box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
            border: 2px solid #e6ebf1;
          }
        .profile-dropdown .header-icon{
            fill:#555b6d !important;
        }
        .header .main-header-end .nav-link.icon {
            border-right: 1px solid #e6ebf1;
        }
        &.dark-mode .header .main-header-end .nav-link.icon {
            border-right: 1px solid #32394e;
        }
        &.dark-mode .profile-dropdown .header-icon{
            fill: #ccd4e0 !important;
        }
        .theme-layout{
            border-left: 0px !important; 
        }
        .desktop-lgo,.darkmobile-logo,.mobile-logo{
            display: none !important;
        }
        .header-brand-img.dark-logo {
            display: block !important;
            margin: 0 auto;
            text-align: center;
            left: 0;
            right: 0;
            position: absolute;
        }
        .header-icon{
            fill:#555b6d !important;
        }
        .app-sidebar__toggle .header-icon{
            fill: #fff !important;
        }
        .nav-link.icon .header-icon {
            background: transparent;
        }
        .nav-link.icon .header-icon {
            margin-top: 3px; 
        }
        .hader-message{
            margin-left:0px;
        }
        &.dark-mode .header-icon{
            fill: #ccd4e0 !important;
        }
    }
    .navbar-toggler-icon {
        color: #fff;
    }
    .navbar-toggler:focus {
        box-shadow: none;
    }
}

.light-header{ 
    .country-selector .nav-link {
        color: #6c756e !important;
    }
    .country-selector {
        padding: 0.3rem;
        border-right: 1px solid #e6ebf1 !important;
      }
    @media (min-width:992px) {
        .hor-header .demo-icon {
            border-right:1px solid #e6ebf1!important;
        }
        .nav-link.theme-layout {
            border-left:1px solid #e6ebf1 !important;
        }
    }
    .hor-header {
        border-bottom: 1px solid rgba(255,255,255,0.1);
    }
    &.dark-mode .profile-dropdown {
        margin-top: 1px;
        border-right: 1px solid #e6ebf1  !important;
    }
    .profile-dropdown img {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
        border: 2px solid #e6ebf1;
      }
    .collapse:not(.show) {
        display: none;
        background: transparent;
    }
    .header {
        background: #fff;
    }
    .app-sidebar__toggle {
        border-right: 1px solid #e6ebf1;
    }
    .app-header{
        border-bottom: 1px solid #e6ebf1 !important;
    }
    .header-icon{
        fill:#555b6d !important;
    }
    .header .form-inline .form-control {
        background: #fff;
        color: #8c97a9;
    }
    .form-control::placeholder{
        color: #bcc1c4 !important;
    }
    &.dark-mode {
        .form-control::placeholder {
             color: #bcc1c4 !important;
             opacity: 0.5 !important;
        }
    }
    .nav-link.icon .header-icon{
        background: transparent;
    }
    .header .main-header-end .nav-link.icon{
        border-right: 1px solid #e6ebf1;
    }
    #fullscreen-button {
        border-left: 1px solid #e6ebf1;
    }
    &.dark-mode .search-element .search-icon {
        fill:#555b6d !important;
    }
    .hor-header { 
        .form-control {
            border: 1px solid #e6ebf1 !important;
        }
        .search-element .btn {
            background-color: #664dc9 !important;
        }
        @media (max-width: 992px) {
            .form-control:focus {
                border-color: #e6ebf1 !important;
            }
        }
        .dark-logo,.mobile-logo,.darkmobile-logo {
            display: none !important;
        }
        .desktop-lgo {
            display: block !important;
        }
        .form-inline .btn .search-icon {
            fill: #ccd4e0 !important;
        }
    }
    @media (min-width: 767px) {
        .hor-header {
            .profile-dropdown img {
                box-shadow: 0 0 0 4px #e6ebf1;
                border: 2px solid #e6ebf1;
            }
        }
    }
    @media (max-width: 767px) {
        &.dark-mode {
            .country-selector .nav-link {
                color: #fff !important;
            }
            .country-selector {
                padding: 0.3rem;
                border-right: 1px solid #32394e !important;
              }
        }
        &.dark-mode .demo-icon svg{
            fill:#555b6d !important;;
        }
        &.dark-mode .profile-dropdown {
            border-right: 1px solid #32394e !important;
        }
        .hor-header {
            .navbar-toggler {
                top: -10px;
            }
            .animated-arrow span {
                background: #664dc9;
            }
            .animated-arrow span:before,.animated-arrow span:after {
                background: #664dc9;
            }
        }
        .profile-dropdown .header-icon{
            fill:#555b6d !important;
        }
        .header .main-header-end .nav-link.icon {
            border-right: 1px solid #e6ebf1;
        }
        &.dark-mode .header .main-header-end .nav-link.icon {
            border-right: 1px solid #32394e;
        }
        &.dark-mode .profile-dropdown .header-icon{
            fill: #ccd4e0 !important;
        }
        .theme-layout{
            border-left: 0px !important; 
        }
        .dark-logo,.darkmobile-logo,.mobile-logo{
            display: none !important;
        }
        .header-brand-img.desktop-lgo {
            display: block !important;
            margin: 0 auto;
            text-align: center;
            left: 0;
            right: 0;
            position: absolute;
        }
        &.dark-mode {
            .dark-logo,.darkmobile-logo,.mobile-logo{
                display: none !important;
            }
            .header-brand-img.desktop-lgo {
                display: block !important;
                margin: 0 auto;
            }
        }
        .header-icon{
            fill:#555b6d !important;
        }
        .app-sidebar__toggle .header-icon{
            fill:#555b6d !important;
        }
        .nav-link.icon .header-icon {
            background: transparent;
        }
        .nav-link.icon .header-icon {
            margin-top: 3px; 
        }
        .hader-message{
            margin-left:0px;
        }
        &.dark-mode {
            .header-icon{
                fill: #ccd4e0 !important;
            }
            .app-sidebar__toggle .header-icon{
                fill:#555b6d !important;
            }
        }
    }
    .navbar-toggler-icon {
        color: #664dc9;
    }
    .navbar-toggler:focus {
        box-shadow: none;
    }
}

.gradient-header{
    .country-selector .nav-link {
        color: #fff !important;
    }
    .country-selector {
        padding: 0.3rem;
        border-right: 1px solid #755dd3 !important;
      }
    @media (min-width:992px) {
        .hor-header .header-search {
            border: 1px solid  #755dd3 !important;
        }
        .hor-header .demo-icon {
            border-right:1px solid #7d67d4 !important;
        }
        .nav-link.theme-layout {
            border-left:1px solid #7d67d4 !important;
        }
    }
    .hor-header {
        border-bottom: 1px solid rgba(255,255,255,0.1);
    }
    .hor-header {
        .desktop-lgo,.darkmobile-logo,.mobile-logo {
            display: none !important;
        }
        .dark-logo {
            display: block !important;
        }
        @media (max-width: 992px) {
            .animated-arrow span{
                background: #ffffff !important;
            }
            .animated-arrow span:before, .animated-arrow span:after {
                background: #ffffff !important;
            }
            .hor-header .header-search {
                border: 1px solid  #e6ebf1 !important;
            }
        }
    }
    .profile-dropdown {
        border-right: 1px solid #7d67d4 !important;
    }
    .collapse:not(.show) {
        display: none;
        background: transparent;
    }
    .header {
        background-image: linear-gradient(to right ,#513da0, #664dc9) !important;
    }
    .app-sidebar__toggle {
        border-right: 1px solid #7d67d4;
    }
    .app-header{
        border-bottom: 1px solid #7d67d4 !important;
    }
    .header-icon{
        fill: #fff !important;
    }
    .header .form-inline .form-control {
        background: transparent;
        color: #fff;
    }
    .form-control::placeholder{
        color: #ffffff !important;
    }
    &.dark-mode {
        .form-control::placeholder {
             color: #ffffff !important;
             opacity: 0.5 !important;
        }
    }
    .nav-link.icon .header-icon{
        background: transparent;
    }
    .header .main-header-end .nav-link.icon{
        border-right: 1px solid #7d67d4;
    }
    #fullscreen-button {
        border-left: 1px solid #7d67d4;
    }
    @media (min-width: 767px) {
        .profile-dropdown img {
            box-shadow: 0px 2px 3px rgba(4, 4, 7 , 0.1);
            border: 2px solid #755dd3;
        }
        .form-control::placeholder{
            color: #ffffff !important;
        }
        .hor-header .search-element .btn {
            background-color: transparent !important;
            .search-icon {
                fill: #ccd4e0 !important;
            }
        }
    }
    @media (max-width: 767px) {
        .country-selector .nav-link {
            color: #6c756e !important;
        }
        .country-selector {
            padding: 0.3rem;
            border-right: 1px solid #e6ebf1 !important;
          }
          &.dark-mode {
            .country-selector .nav-link {
                color: #fff !important;
            }
            .country-selector {
                padding: 0.3rem;
                border-right: 1px solid #32394e !important;
              }
          }
        .demo-icon svg {
            fill: #fff !important;
        }
        .header-icon.settings-icon {
            fill:#fff !important;
        }
        &.dark-mode .header-icon.settings-icon {
            fill:#fff !important;
        }
        .profile-dropdown {
            border-right: 1px solid #e6ebf1 !important;
        }
        &.dark-mode .profile-dropdown {
            border-right: 1px solid #32394e !important;
        }
        .profile-dropdown .header-icon{
            fill:#555b6d !important;
        }
        .header .main-header-end .nav-link.icon {
            border-right: 1px solid #e6ebf1;
        }
        &.dark-mode .header .main-header-end .nav-link.icon {
            border-right: 1px solid #32394e;
        }
        &.dark-mode .profile-dropdown .header-icon{
            fill: #ccd4e0 !important;
        }
        .theme-layout{
            border-left: 0px !important; 
        }
        .desktop-lgo,.darkmobile-logo,.mobile-logo{
            display: none !important;
        }
        .header-brand-img.dark-logo {
            display: block !important;
            margin: 0 auto;
            text-align: center;
            left: 0;
            right: 0;
            position: absolute;
        }
        .header-icon{
            fill:#555b6d !important;
        }
        .app-sidebar__toggle .header-icon{
            fill: #fff !important;
        }
        .nav-link.icon .header-icon {
            background: transparent;
        }
        .nav-link.icon .header-icon {
            margin-top: 3px; 
        }
        .hader-message{
            margin-left:0px;
        }
        &.dark-mode .header-icon{
            fill: #ccd4e0 !important;
        }
    }
    .navbar-toggler-icon {
        color: #fff;
    }
    .navbar-toggler:focus {
        box-shadow: none;
    }
}


.color-menu {
    .sub-side-menu__label2 {
        color: #ccd4e0;
    }
    .sub-angle2 {
        color: #ccd4e0;
    }
    .sub-slide-item2 {
        color: #ccd4e0;
    }
    .slide-menu a.active {
        color: #ffffff !important;
    }
    .sub-side-menu__item{
        color: #ccd4e0;
    }
    .sub-slide-item{
        color: #ccd4e0;
    }
    .desktop-lgo {
        display: none;
    }
    .dark-logo {
        display: block !important;
        margin: 0 auto;
    }
    .app-sidebar{
        background: #664dc9 !important;
    }
    .app-sidebar__logo {
        border-bottom: 1px solid #755dd3;
        border-right: 1px solid #755dd3;
        background: #664dc9 ;
        padding: 14px 40px;
    }
    .app-sidebar .side-item.side-item-category {
        color: #ccd4e0;
    }
    .app-sidebar {
        border-right: 1px solid #755dd3;
    }
    .app-sidebar__user {
        border-bottom: 1px solid #755dd3;
    }
    .slide.is-expanded .slide-menu li a:hover {
        color: #fff;
    }
    .app-sidebar__user .user-pic img {
        border: 3px solid #755dd3;
        box-shadow: 0 0 0 4px #755dd3;
    }
    .app-sidebar__user-name {
        color: #ccd4e0b5 !important;
    }
    .app-sidebar{
        border-top: 1px solid #755dd3;
    }
    .user-info h5{
        color: #ccd4e0;
    }
    .side-menu__item {
        &.active,&:hover,&:focus {
            background: #ccd4e01c;
            color: #fff !important;
            .side-menu__label {
                color: #fff !important;
            }
            .side-menu__icon {
                fill: #fff !important;
            }
            i {
                color: #fff !important;
            }
        }
    }
    .side-menu__label {
        color: #ccd4e0 !important;
    }
    .side-menu__icon {
        fill: #ccd4e0 !important;
    }
    .side-menu__item i{
        color: #ccd4e0;
    }
    .slide-item {
        color: #ccd4e0;
    }

    &.sidenav-toggled1 {
        .side-menu__item:focus {
            background: #ccd4e01c !important;
        }
    }
    @media (min-width: 768px) {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
            border-right: 1px solid #755dd3 !important;
        }
        &.sidenav-toggled {
            &.sidenav-toggled1 .app-sidebar .app-sidebar__user {
            border-bottom: 1px solid #755dd3 !important;
            }
            .desktop-lgo,.dark-logo,.mobile-logo {
                display: none !important;
            }
            .darkmobile-logo {
                display: block !important;
                margin: 0 auto;
            }
            &.sidenav-toggled1{ 
                .desktop-lgo,.darkmobile-logo,.mobile-logo {
                     display: none !important;
                }
                .dark-logo {
                    display: block !important;
                    margin: 0 auto;
                }
                .app-sidebar__logo {
                    padding: 14px 40px;
                }
                .header-brand-img.dark-logo {
                     margin-top: 0px;
                }
            }  
        }
        .app-sidebar__logo {
            border-right: 1px solid #755dd3 !important;
        }
    }
}

.dark-menu {
    .sub-side-menu__label2 {
        color: #ccd4e0;
    }
    .sub-angle2 {
        color: #ccd4e0;
    }
    .sub-slide-item2 {
        color: #ccd4e0;
    }
    .slide-menu a.active {
        color: #ffffff !important;
    }
    .sub-side-menu__item{
        color: #ccd4e0;
    }
    .sub-slide-item{
        color: #ccd4e0;
    }
    .desktop-lgo {
        display: none;
    }
    .dark-logo {
        display: block !important;
        margin: 0 auto;
    }
    .app-sidebar{
        background: #2a2e3f !important;
    }
    .app-sidebar__logo {
        border-bottom: 1px solid #32394e;
        border-right: 1px solid #32394e;
        background: #2a2e3f ;
        padding: 14px 40px;
    }
    .app-sidebar .side-item.side-item-category {
        color: #ccd4e0;
    }
    .app-sidebar {
        border-right: 1px solid #32394e;
    }
    .app-sidebar__user {
        border-bottom: 1px solid #32394e;
    }
    .slide.is-expanded .slide-menu li a:hover {
        color: #fff;
    }
    .app-sidebar__user .user-pic img {
        border: 3px solid #32394e;
        box-shadow: 0 0 0 4px #32394e;
    }
    .app-sidebar__user-name {
        color: #ccd4e0b5 !important;
    }
    .app-sidebar{
        border-top: 1px solid #32394e;
    }
    .user-info h5{
        color: #ccd4e0;
    }
    .side-menu__item {
        &.active,&:hover,&:focus {
            background: #ccd4e01c;
            color: #fff !important;
            .side-menu__label {
                color: #fff !important;
            }
            .side-menu__icon {
                fill: #fff !important;
            }
            i {
                color: #fff !important;
            }
        }
    }
    .side-menu__label {
        color: #ccd4e0 !important;
    }
    .side-menu__icon {
        fill: #ccd4e0 !important;
    }
    .side-menu__item i{
        color: #ccd4e0;
    }
    .slide-item {
        color: #ccd4e0;
    }

    &.sidenav-toggled1 {
        .side-menu__item:focus {
            background: #ccd4e01c !important;
        }
    }
    @media (min-width: 768px) {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
            border-right: 1px solid #32394e !important;
        }
        &.sidenav-toggled {
            &.sidenav-toggled1 .app-sidebar .app-sidebar__user {
            border-bottom: 1px solid #32394e !important;
            }
            .desktop-lgo,.dark-logo,.mobile-logo {
                display: none !important;
            }
            .darkmobile-logo {
                display: block !important;
                margin: 0 auto;
            }
            &.sidenav-toggled1{ 
                .desktop-lgo,.darkmobile-logo,.mobile-logo {
                     display: none !important;
                }
                .dark-logo {
                    display: block !important;
                    margin: 0 auto;
                }
                .app-sidebar__logo {
                    padding: 14px 40px;
                }
                .header-brand-img.dark-logo {
                     margin-top: 0px;
                }
            }  
        }
        .app-sidebar__logo {
            border-right: 1px solid #32394e !important;
        }
    }
}

.light-menu {
    .side-menu__item:hover .angle {
        color: #6c756e !important;
    }
    &.dark-mode .hor-header {
        .desktop-lgo {
            display: none !important;
        }
        .dark-logo {
            display: block !important;
            margin: 0 auto;
        }
    }
    .desktop-lgo {
        display: none;
    }
    .dark-logo {
        display: block !important;
        margin: 0 auto;
    }
    .app-sidebar{
        background: #fff !important;
    }
    .app-sidebar__logo {
        border-bottom: 1px solid #e6ebf1;
        border-right: 1px solid #e6ebf1;
        background: #fff ;
        padding: 14px 40px;
    }
    .app-sidebar .side-item.side-item-category {
        color: #ccd4e0;
    }
    .app-sidebar {
        border-right: 1px solid #e6ebf1;
    }
    .app-sidebar__user {
        border-bottom: 1px solid #e6ebf1;
    }
    .slide.is-expanded .slide-menu li a:hover {
        color: #664dc9;
    }
    .app-sidebar__user .user-pic img {
        border: 3px solid #e6ebf1;
        box-shadow: 0 0 0 4px #e6ebf1;
    }
    .app-sidebar__user-name {
        color: #ccd4e0b5 !important;
    }
    .side-menu__item {
        &.active,&:hover,&:focus {
            background: #f5f6f8;
            color: #664dc9 !important;
            .side-menu__label {
                color: #664dc9 !important;
            }
            .side-menu__icon {
                fill:#664dc9 !important;
            }
            i {
                color: #664dc9 !important;
            }
        }
    }
    .side-menu__label {
        color: #6c756e !important;
    }
    .side-menu__icon {
        fill:#555b6d !important;
    }
    .side-menu__item i{
        color: #6c756e;
    }
    .slide-item {
        color: #6c756e;
    }

    &.sidenav-toggled1 {
        .side-menu__item:focus {
            background: #ccd4e01c !important;
        }
    }
    .darkmobile-logo,.dark-logo,.mobile-logo {
        display: none !important;
    }
    .desktop-lgo {
        display: block !important;
        margin: 0 auto;
    }
    .side-item.side-item-category {
        color: #9993b5 !important;
    }
    .text-muted {
        color: #adb1c5 !important;
    }
    &.dark-mode .user-info h5{
        color:#6c756e !important;
    }
    @media (min-width: 768px) {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
            border-right: 1px solid #e6ebf1 !important;
        }
        &.sidenav-toggled {
            &.sidenav-toggled1 .app-sidebar .app-sidebar__user {
            border-bottom: 1px solid #e6ebf1 !important;
            }
            .darkmobile-logo,.dark-logo,.desktop-lgo {
                display: none !important;
            }
            .mobile-logo
             {
                display: block !important;
                margin: 0 auto;
            }
            &.sidenav-toggled1{ 
                .app-sidebar__logo {
                    border-right: 1px solid #e6ebf1 !important;
                }
                .mobile-logo,.darkmobile-logo,.dark-logo {
                     display: none !important;
                }
                .desktop-lgo {
                    display: block !important;
                    margin: 0 auto;
                }
                .app-sidebar__logo {
                    padding: 14px 40px;
                }
                .header-brand-img.dark-logo {
                     margin-top: 0px;
                }
            }  
        }
    }
}

.gradient-menu {
    .sub-side-menu__label2 {
        color: #ccd4e0;
    }
    .sub-angle2 {
        color: #ccd4e0;
    }
    .sub-slide-item2 {
        color: #ccd4e0;
    }
    .slide-menu a.active {
        color: #ffffff !important;
    }
    .sub-side-menu__item{
        color: #ccd4e0;
    }
    .sub-slide-item{
        color: #ccd4e0;
    }
    .desktop-lgo {
        display: none;
    }
    .dark-logo {
        display: block !important;
        margin: 0 auto;
    }
    .app-sidebar{
        background-image: linear-gradient(to bottom ,#513da0, #664dc9) !important;
    }
    .app-sidebar__logo {
        border-bottom: 1px solid #755dd3;
        border-right: 1px solid #755dd3;
        background: #5540a8 ;
        padding: 14px 40px;
    }
    .app-sidebar .side-item.side-item-category {
        color: #ccd4e0;
    }
    .app-sidebar {
        border-right: 1px solid #755dd3;
    }
    .app-sidebar__user {
        border-bottom: 1px solid #755dd3;
    }
    .slide.is-expanded .slide-menu li a:hover {
        color: #fff;
    }
    .app-sidebar__user .user-pic img {
        border: 3px solid #755dd3;
        box-shadow: 0 0 0 4px #755dd3;
    }
    .app-sidebar__user-name {
        color: #ccd4e0b5 !important;
    }
    .app-sidebar{
        border-top: 1px solid #755dd3;
    }
    .user-info h5{
        color: #ccd4e0;
    }
    .side-menu__item {
        &.active,&:hover,&:focus {
            background: #ccd4e01c;
            color: #fff !important;
            .side-menu__label {
                color: #fff !important;
            }
            .side-menu__icon {
                fill: #fff !important;
            }
            i {
                color: #fff !important;
            }
        }
    }
    .side-menu__label {
        color: #ccd4e0 !important;
    }
    .side-menu__icon {
        fill: #ccd4e0 !important;
    }
    .side-menu__item i{
        color: #ccd4e0;
    }
    .slide-item {
        color: #ccd4e0;
    }

    &.sidenav-toggled1 {
        .side-menu__item:focus {
            background: #ccd4e01c !important;
        }
    }
    @media (min-width: 768px) {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
            border-right: 1px solid #755dd3 !important;
        }
        &.sidenav-toggled {
            &.sidenav-toggled1 .app-sidebar .app-sidebar__user {
            border-bottom: 1px solid #755dd3 !important;
            }
            .desktop-lgo,.dark-logo,.mobile-logo {
                display: none !important;
            }
            .darkmobile-logo {
                display: block !important;
                margin: 0 auto;
            }
            &.sidenav-toggled1{ 
                .desktop-lgo,.darkmobile-logo,.mobile-logo {
                     display: none !important;
                }
                .dark-logo {
                    display: block !important;
                    margin: 0 auto;
                }
                .app-sidebar__logo {
                    padding: 14px 40px;
                }
                .header-brand-img.dark-logo {
                     margin-top: 0px;
                }
            }  
        }
        .app-sidebar__logo {
            border-right: 1px solid #755dd3 !important;
        }
    }
}


/*--Boxed--*/
@media (min-width: 1025px) {
	body.layout-boxed {
		background: #cfd1e2;
	}
	body.layout-boxed.dark-mode {
		background:#414165
	}
	.layout-boxed{
		.page {
			width: 1400px;
			margin: 0 auto;
			background:#f2f3f9;
			position: relative;
			display: -ms-flexbox;
			display: -webkit-box;
			display: flex;
			-ms-flex-direction: column;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			flex-direction: column;
			-ms-flex-pack: center;
			-webkit-box-pack: center;
			-webkit-box-shadow: rgba(104, 113, 123, 0.3) 0px 5px 0px 0px, rgba(104, 113, 123, 0.3) 0px 5px 25px 0px;
			box-shadow: rgba(104, 113, 123, 0.3) 0px 5px 0px 0px, rgba(104, 113, 123, 0.3) 0px 5px 25px 0px;
		}
		.app-header.header{
			width: 1400px !important;
    		margin: 0 auto;
		}
		.app-sidebar{
			left: auto !important; 
		}
		.side-header {
			left: auto !important; 
			right: auto !important; 
		}
		h3.number-font{
			font-size: 1.8rem;
		}
		.sticky-pin .horizontal-main.hor-menu{
			width: 1400px !important;
		}
		.hor-content .container,  .hor-header .container, .horizontal-main.hor-menu .container{
			max-width: 95%;
		}
        .recent-activity {
            height: 430px !important;
        }
	}
	.dark-mode.layout-boxed .page{
		background:#22223d;
		-webkit-box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
			box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
	}
}

/*--- Scrollabel-header ----*/
.scrollable-layout{
	.app-header{
		position: absolute !important;
	}
	.app-sidebar{
		position: absolute;
	}
	.app-sidebar__logo{
		position: absolute;
	}
	.page {
		position: relative;
	}
	.sticky.sticky-pin{
		position: absolute;
	}
} 


.light-hormenu {
    &.dark-mode {
        .dark-mode .hor-header {
             border-bottom: 0px !important;
        }
    }
    &.dark-mode .horizontal-main.hor-menu {
        background-color: #fff !important;
    }
    .hor-icon{
        color: #664dc9 !important;
        fill:#555b6d !important;
    }
    .hor-menu a {
        color:#6c756e;
    }
     .hor-menu .horizontalMenu>.horizontalMenu-list>li>a:hover {
        background: #f5f6f8;
        box-shadow: none;
        color: #664dc9;
    }
    .hor-menu .horizontalMenu>.horizontalMenu-list>li>a.active {
        background: #f5f6f8;
        box-shadow: none;
        color: #664dc9;
    }
    @media only screen and (max-width: 992px) {
        &.dark-mode {
            .horizontalMenu>.horizontalMenu-list {
            background: #fff !important;
                .sub-icon {
                    color:#6c756e;
                }
            }
            .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i {
                color: #664dc9;
            }
            .mega-menubg {
                background: #f0f0f8 !important;
            }
            .horizontalMenu-list>li{
                color : #f0f0f8 !important;
            }
            .mega-menubg {
                background: #f0f0f8 !important;
                h3{
                    color: #6c756e;
                }
                .link-list li a {
                    color: #6c756e !important;
                     &:hover {
                         color:#664dc9 !important;
                     }
                }
            }
            li  ul.sub-menu {
                background-color: #f0f0f8 !important;
                color:#6c756e !important;
            }
            .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
                 box-shadow:none !important; 
            }
            .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
                color: #6c756e;
                &:hover {
                    color:#664dc9 !important;
                }
            }
        }       
    }
}

.dark-hormenu {
    .horizontal-main.hor-menu {
        background-color: #2a2e3f !important;
    }
    .hor-icon{
        color: #ccd4e0 !important;
        fill: #ccd4e0 !important;
    }
    .hor-menu a {
        color:#ccd4e0;
    }
     .hor-menu .horizontalMenu>.horizontalMenu-list>li>a:hover {
        background: #ccd4e01c;
        box-shadow: none;
        color: #fff;
    }
    .hor-menu .horizontalMenu>.horizontalMenu-list>li>a.active {
        background: #ccd4e01c;
        box-shadow: none;
        color: #fff;
    }
    @media only screen and (max-width: 992px) {

        .horizontal-megamenu .link-list li h3 {
            color: #d8dbec !important;
        }
        .horizontalMenu>.horizontalMenu-list {
        background: #2a2e3f !important;
            .sub-icon {
                color:#ccd4e0;
            }
        }
        .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i {
            color: #ccd4e0;
        }
        .mega-menubg {
            background: #2a2e3f !important;
        }
        .horizontalMenu-list>li{
            color : #ccd4e0 !important;
        }
        .mega-menubg {
            background: #2a2e3f !important;
            h3{
                color: #ccd4e0;
            }
            .link-list li a {
                color: #ccd4e0 !important;
                    &:hover {
                        color:#fff !important;
                    }
            }
        }
        li  ul.sub-menu {
            background-color: #2a2e3f !important;
            color:#ccd4e0 !important;
        }
        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
                box-shadow:none !important; 
        }
        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
            color: #ccd4e0 !important;
            &:hover {
                color:#fff !important;
            }
        }
    }  
}

.color-hormenu {
    .horizontal-main.hor-menu {
        background-color: #664dc9 !important;
    }
    .hor-icon{
        color: #ccd4e0 !important;
        fill: #ccd4e0 !important;
    }
    .hor-menu a {
        color:#ccd4e0;
    }
     .hor-menu .horizontalMenu>.horizontalMenu-list>li>a:hover {
        background: #ccd4e01c;
        box-shadow: none;
        color: #fff;
    }
    .hor-menu .horizontalMenu>.horizontalMenu-list>li>a.active {
        background: #ccd4e01c;
        box-shadow: none;
        color: #fff;
    }
    @media only screen and (max-width: 992px) {
        .horizontal-megamenu .link-list li h3 {
            color: #d8dbec !important;
        }
        .horizontalMenu>.horizontalMenu-list {
        background: #664dc9 !important;
            .sub-icon {
                color:#ccd4e0;
            }
        }
        .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i {
            color: #ccd4e0;
        }
        .mega-menubg {
            background: #664dc9 !important;
        }
        .horizontalMenu-list>li{
            color : #ccd4e0 !important;
        }
        .mega-menubg {
            background: #664dc9 !important;
            h3{
                color: #ccd4e0;
            }
            .link-list li a {
                color: #ccd4e0 !important;
                    &:hover {
                        color:#fff !important;
                    }
            }
        }
        li  ul.sub-menu {
            background-color: #664dc9 !important;
            color:#ccd4e0 !important;
        }
        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
                box-shadow:none !important; 
        }
        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
            color: #ccd4e0 !important;
            &:hover {
                color:#fff !important;
            }
        }

        &.dark-mode {
            .horizontalMenu>.horizontalMenu-list {
                background: #664dc9 !important;
                .sub-icon {
                    color:#ccd4e0;
                }
            }
            .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i {
                color: #ccd4e0;
            }
            .mega-menubg {
                background: #664dc9 !important;
            }
            .horizontalMenu-list>li{
                color : #ccd4e0 !important;
            }
            .mega-menubg{
                background: #664dc9 !important;
                h3{
                    color: #ccd4e0;
                }
                .link-list li a {
                    color: #ccd4e0 !important;
                        &:hover {
                            color:#fff !important;
                        }
                }
            }
            li  ul.sub-menu {
                background-color: #664dc9 !important;
                color:#ccd4e0 !important;
            }
            li  ul.sub-menu:hover {
                background-color: #664dc9 !important;
                color:#ccd4e0 !important;
            }
            .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
                    box-shadow:none !important; 
            }
            .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
                color: #ccd4e0 !important;
                &:hover {
                    color:#fff !important;
                }
            }
        }
    }  
}

.gradient-hormenu {
    .horizontal-main.hor-menu {
        background-image: linear-gradient(to right ,#513da0, #664dc9) !important;
    }
    .hor-icon{
        color: #ccd4e0 !important;
        fill: #ccd4e0 !important;
    }
    .hor-menu a {
        color:#ccd4e0;
    }
     .hor-menu .horizontalMenu>.horizontalMenu-list>li>a:hover {
        background: #ccd4e01c;
        box-shadow: none;
        color: #fff;
    }
    .hor-menu .horizontalMenu>.horizontalMenu-list>li>a.active {
        background: #ccd4e01c;
        box-shadow: none;
        color: #fff;
    }
    @media only screen and (max-width: 992px) {
        .horizontal-megamenu .link-list li h3 {
            color: #d8dbec !important;
        }
        .horizontalMenu>.horizontalMenu-list {
        background: #664dc9 !important;
            .sub-icon {
                color:#ccd4e0;
            }
        }
        .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i {
            color: #ccd4e0;
        }
        .mega-menubg {
            background: #664dc9 !important;
        }
        .horizontalMenu-list>li{
            color : #ccd4e0 !important;
        }
        .mega-menubg {
            background: #664dc9 !important;
            h3{
                color: #ccd4e0;
            }
            .link-list li a {
                color: #ccd4e0 !important;
                    &:hover {
                        color:#fff !important;
                    }
            }
        }
        li  ul.sub-menu {
            background-color: #664dc9 !important;
            color:#ccd4e0 !important;
        }
        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
                box-shadow:none !important; 
        }
        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
            color: #ccd4e0 !important;
            &:hover {
                color:#fff !important;
            }
        }

        &.dark-mode {
            .horizontalMenu>.horizontalMenu-list {
                background-image: linear-gradient(to right ,#513da0, #664dc9) !important;
                    .sub-icon {
                        color:#ccd4e0;
                    }
                }
                .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i {
                    color: #ccd4e0;
                }
                .mega-menubg {
                    background: #664dc9 !important;
                }
                .horizontalMenu-list>li{
                    color : #ccd4e0 !important;
                }
                .mega-menubg {
                    background: #664dc9 !important;
                    h3{
                        color: #ccd4e0;
                    }
                    .link-list li a {
                        color: #ccd4e0 !important;
                            &:hover {
                                color:#fff !important;
                            }
                    }
                }
                li  ul.sub-menu {
                    background-color: #664dc9 !important;
                    color:#ccd4e0 !important;
                }
                .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
                        box-shadow:none !important; 
                }
                .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
                    color: #ccd4e0 !important;
                    &:hover {
                        color:#fff !important;
                    }
                }
        }
    }  
}

.light-header.light-hormenu {
    .hor-header {
        border-bottom: 1px solid rgba(0,0,0,0.1) !important;
    }
}
.dark-header.dark-hormenu {
    .hor-header {
        border-bottom: 1px solid rgba(255,255,255,0.1) !important;
    }
}

.dark-mode .bg-left2, .light-mode #background-left1, .light-header .bg1, .color-header .bg2, .dark-header .bg3, .gradient-header .bg8, .light-menu .bg4, .color-menu .bg5, .dark-menu .bg6, .gradient-menu .bg7, .layout-fullwidth #background14, .layout-boxed #background15, .menu1 .bg-sided, .menu2 .bg-sideictxt, .menu3 .bg-sideicon, .fixed-layout #background16, .scrollable-layout #background17, .dark-mode .bg-hor2, .light-mode .bg-hor1, .light-header .bg-hor3, .color-header .bg-hor4, .dark-header .bg-hor5, .gradient-header .bg-hor10, .light-hormenu #background7, .color-hormenu .bg-hor7, .dark-hormenu .bg-hor8, .gradient-hormenu .bg-hor9 {
    border: 3px solid #6c5ffc !important;
}
.dark-mode .bg-left2:before, .light-mode #background-left1::before, .dark-mode .bg-hor2:before, .light-mode .bg-hor1:before, .layout-fullwidth #background14:before, .layout-boxed #background15:before, .fixed-layout #background16:before, .scrollable-layout #background17:before {
    content: "\2713";
    z-index: 999;
    position: absolute;
    top: 4px;
    right: 21px;
    font-size: 14px;
    color: #ffffff;
}
.dark-mode .bg-left2:after, .light-mode #background-left1:after, .dark-mode .bg-hor2:after, .light-mode .bg-hor1:after, .layout-fullwidth #background14:after, .layout-boxed #background15:after, .fixed-layout #background16:after, .scrollable-layout #background17:after {
    content: "";
    width: 20px;
    height: 20px;
    line-height: 17px;
    border-style: solid;
    border-width: 0 35px 35px 0px;
    border-color: #6c5ffc #6c5ffc transparent;
    display: block;
    position: absolute;
    top: 4px;
    right: 17px;
}
.light-header .bg1:before, .color-header .bg2:before, .dark-header .bg3:before, .gradient-header .bg8:before, .light-menu .bg4:before, .color-menu .bg5:before, .dark-menu .bg6:before, .gradient-menu .bg7:before, .menu1 .bg-sided:before, .menu2 .bg-sideictxt:before, .menu3 .bg-sideicon:before, .light-header .bg-hor3:before, .color-header .bg-hor4:before, .dark-header .bg-hor5:before, .gradient-header .bg-hor10:before, .light-hormenu #background7:before, .color-hormenu .bg-hor7:before, .dark-hormenu .bg-hor8:before, .gradient-hormenu .bg-hor9:before {
    content: "\2713";
    z-index: 999;
    position: absolute;
    top: 4px;
    right: 24px;
    font-size: 14px;
    color: #ffffff;
}
.light-header .bg1:after, .color-header .bg2:after, .dark-header .bg3:after, .gradient-header .bg8:after, .light-menu .bg4:after, .color-menu .bg5:after, .dark-menu .bg6:after, .gradient-menu .bg7:after, .menu1 .bg-sided:after, .menu2 .bg-sideictxt:after, .menu3 .bg-sideicon:after, .light-header .bg-hor3:after, .color-header .bg-hor4:after, .dark-header .bg-hor5:after, .gradient-header .bg-hor10:after, .light-hormenu #background7:after, .color-hormenu .bg-hor7:after, .dark-hormenu .bg-hor8:after, .gradient-hormenu .bg-hor9:after {
    content: "";
    width: 20px;
    height: 20px;
    line-height: 17px;
    border-style: solid;
    border-width: 0 35px 35px 0px;
    border-color: #6c5ffc #6c5ffc transparent;
    display: block;
    position: absolute;
    top: 4px;
    right: 20px;
}



/*--- Only Demo Purpose scss Icon-Text----*/
@media (min-width: 768px) {
    .icontext-menu.sidebar-mini.sidenav-toggled .app-sidebar {
        width: 120px;
    }

    .icontext-menu.app.sidebar-mini.sidenav-toggled .side-header {
        width: 120px !important;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled .app-content {
        margin-left: 120px;
    }

    .icontext-menu.app.sidebar-mini.sidenav-toggled .side-menu__item {
        padding: 12px 5px;
        text-align: center;
        display: block;
    }

    .icontext-menu.app.sidebar-mini.sidenav-toggled .side-menu__label {
        display: block !important;
        font-size: 12px;
        padding-top: 0px;
        margin-top: 0;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled .app-header.header {
        padding-left: 130px;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon:after {
        top: 6px;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar {
        width: 270px;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header {
        width: 270px !important;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__item {
        display: flex;
        padding: 10px 20px;
        text-align: inherit;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__label {
        display: block !important;
        margin-top: 3px;
        font-size: 15px;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu .side-menu__icon:after {
        top: 12px;
    }
    .icontext-menu.sidenav-toggled .app-sidebar__logo {
        width: 119px;
    }
}

